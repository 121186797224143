
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Strategy from "@/models/strategy/Strategy";
import RangeConfiguration from "@/pages/strategy/components/RangeConfiguration.vue";
import SubscopeRate from "@/models/strategy/SubscopeRate";
import SubScopeRateField from "@/pages/strategy/components/SubScopeRateField.vue";
import VueNumeric from "@/pages/shared/components/VueNumeric.vue";
import { cloneDeep, isEqual } from "lodash";

@Component({
  components: { SubScopeRateField, RangeConfiguration, VueNumeric },
})
export default class StrategyInfoTable extends Vue {
  @Prop({ required: true }) strategy!: Strategy;
  @Prop({ required: true }) rangeIndex!: number;
  @Prop({ required: true }) isBaseline!: boolean;
  @Prop({ required: true }) isPercentGrowth!: boolean;

  beforeChangeState = new Strategy(-1, "", -1, [], [], [], [], [], []);

  get startYear(): number {
    return this.$store.state.baseYear + 1;
  }

  get targetYear(): number {
    return this.$store.state.targetYear;
  }

  get ssp(): string {
    return this.$store.state.ssp.name;
  }

  get decimalSeparator(): string {
    return this.$store.state.configStore.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.$store.state.configStore.separator;
  }

  scope1SubscopeRates(idx: number): SubscopeRate[] {
    return this.strategy.scope1Rate[idx].subscopeRates;
  }

  scope2SubscopeRates(idx: number): SubscopeRate[] {
    return this.strategy.scope2Rate[idx].subscopeRates;
  }

  scope3SubscopeRates(idx: number): SubscopeRate[] {
    return this.strategy.scope3Rate[idx].subscopeRates;
  }

  //---------------------------------------
  // getter and setter to allow diff modes

  get numberOfYears(): number {
    const range = this.strategy.yearRanges[this.rangeIndex];

    return range.endYear - range.startYear + 1;
  }

  get ebitdaRate(): number {
    const gvaRate = this.strategy.gvaRate[this.rangeIndex];

    if (this.isPercentGrowth) {
      return gvaRate.ebitdaRate;
    } else {
      return gvaRate.calculateTargetEbitda(this.numberOfYears);
    }
  }

  set ebitdaRate(newValue: number) {
    if (this.isPercentGrowth) {
      this.strategy.gvaRate[this.rangeIndex].ebitdaRate = newValue;
    } else {
      this.strategy.gvaRate[this.rangeIndex].storePercentageEbitda(
        newValue,
        this.numberOfYears
      );
    }
  }

  get personnelCostsRate(): number {
    const gvaRate = this.strategy.gvaRate[this.rangeIndex];

    if (this.isPercentGrowth) {
      return gvaRate.personnelCostsRate;
    } else {
      return gvaRate.calculateTargetPersonnelCost(this.numberOfYears);
    }
  }

  set personnelCostsRate(newValue: number) {
    if (this.isPercentGrowth) {
      this.strategy.gvaRate[this.rangeIndex].personnelCostsRate = newValue;
    } else {
      this.strategy.gvaRate[this.rangeIndex].storePercentagePersonnelCost(
        newValue,
        this.numberOfYears
      );
    }
  }

  get scope1OtherRate(): number {
    const rate = this.strategy.scope1Rate[this.rangeIndex];

    if (this.isPercentGrowth) {
      return rate.otherRate;
    } else {
      return rate.calculateTargetOtherRate(this.numberOfYears);
    }
  }

  set scope1OtherRate(newValue: number) {
    if (this.isPercentGrowth) {
      this.strategy.scope1Rate[this.rangeIndex].otherRate = newValue;
    } else {
      this.strategy.scope1Rate[this.rangeIndex].storePercentageOtherRate(
        newValue,
        this.numberOfYears
      );
    }
  }

  get scope2OtherRate(): number {
    const rate = this.strategy.scope2Rate[this.rangeIndex];

    if (this.isPercentGrowth) {
      return rate.otherRate;
    } else {
      return rate.calculateTargetOtherRate(this.numberOfYears);
    }
  }

  set scope2OtherRate(newValue: number) {
    if (this.isPercentGrowth) {
      this.strategy.scope2Rate[this.rangeIndex].otherRate = newValue;
    } else {
      this.strategy.scope2Rate[this.rangeIndex].storePercentageOtherRate(
        newValue,
        this.numberOfYears
      );
    }
  }

  get scope3OtherRate(): number {
    const rate = this.strategy.scope3Rate[this.rangeIndex];

    if (this.isPercentGrowth) {
      return rate.otherRate;
    } else {
      return rate.calculateTargetOtherRate(this.numberOfYears);
    }
  }

  set scope3OtherRate(newValue: number) {
    if (this.isPercentGrowth) {
      this.strategy.scope3Rate[this.rangeIndex].otherRate = newValue;
    } else {
      this.strategy.scope3Rate[this.rangeIndex].storePercentageOtherRate(
        newValue,
        this.numberOfYears
      );
    }
  }

  beforeRecalculateXDC(): void {
    this.beforeChangeState = cloneDeep(this.strategy);
  }

  recalculateXDC(forceRecalculate = false): void {
    if (!isEqual(this.strategy, this.beforeChangeState) || forceRecalculate) {
      this.$store.dispatch("recalculateStrategyInitialValues", this.strategy);
      this.$store.dispatch("makeStrategyXDCRequest", this.strategy);
      this.$store.commit("curvesNeedReload", true);

      if (this.$store.state.configStore.autoSave) {
        this.$store.dispatch("sessionStore/saveSession");
      }
    }
  }

  @Watch("isPercentGrowth")
  // iterate through all scopeRates and call setter to reformat 0 values if absolute
  onIsPercentGrowthSwitchUpdateEmissions(): void {
    if (this.scope1OtherRate === 0 && !this.isPercentGrowth) {
      this.scope1OtherRate = 0;
    }

    if (this.scope2OtherRate === 0 && !this.isPercentGrowth) {
      this.scope2OtherRate = 0;
    }

    if (this.scope3OtherRate === 0 && !this.isPercentGrowth) {
      this.scope3OtherRate = 0;
    }

    this.recalculateXDC(true);
  }
}
