export default class NACESector {
  code: string;
  name: string;
  full: string;
  isDisabled: boolean;
  year?: number;

  constructor(
    code: string,
    name: string,
    isDisabled = false,
    year: number | undefined = undefined
  ) {
    this.code = code;
    this.name = name;
    this.full = `${code} - ${name}`;
    this.isDisabled = isDisabled;
    this.year = year;
  }
}
