import Subscope from "@/models/Subscope";
import SubscopeRate from "@/models/strategy/SubscopeRate";

export default class ScopeRate {
  initialOtherEmissions: number;
  otherRate: number;
  subscopeRates: Array<SubscopeRate>;

  constructor(
    total = 0,
    initialOtherEmissions = 0,
    subscopeRates: Array<SubscopeRate> = []
  ) {
    this.otherRate = total;
    this.initialOtherEmissions = initialOtherEmissions;
    this.subscopeRates = subscopeRates;
  }

  addSubscope(scope: Subscope): void {
    const existingIndex = this.subscopeRates.findIndex(
      (s) => s.id === scope.id
    );

    if (existingIndex === -1) {
      this.subscopeRates.push(new SubscopeRate(scope.id, scope.name, 0));
    }
  }

  removeSubscope(scope: Subscope): void {
    this.subscopeRates = this.subscopeRates.filter((s) => s.id !== scope.id);
  }

  calculateTargetOtherRate(numberOfYears: number): number {
    return (
      this.initialOtherEmissions *
      Math.pow(1 + this.otherRate / 100, numberOfYears)
    );
  }

  storePercentageOtherRate(
    finalOtherEmissions: number,
    numberOfYears: number
  ): void {
    // we want to get an asymptotic curve in our results.
    // If absolute emissions were 0, we would get an immediate 'step' in our curves.
    // So we replace these values with 0.00000001 (very small value to avoid changing results)
    const adjustedEmissions =
      finalOtherEmissions === 0 ? 0.00000001 : finalOtherEmissions;
    this.otherRate =
      (Math.pow(
        adjustedEmissions / this.initialOtherEmissions,
        1 / numberOfYears
      ) -
        1) *
      100;
  }

  calculateTargetSubscopeEmission(
    numberOfYears: number,
    subscopeId: string
  ): number {
    const subscopeRate = this.subscopeRates.find((s) => s.id === subscopeId);

    if (subscopeRate) {
      return subscopeRate.calculateTargetEmission(numberOfYears);
    }

    return 0;
  }

  storePercentageSubscopeEmission(
    finalSubscopeEmission: number,
    numberOfYears: number,
    subscopeId: string
  ): void {
    const subscope = this.subscopeRates.find((s) => s.id === subscopeId);

    if (subscope) {
      subscope.storePercentageEmissionRate(
        finalSubscopeEmission,
        numberOfYears
      );
    }
  }

  getSubscopeValue(subscopeId: string): number {
    const subscope = this.subscopeRates.find((s) => s.id === subscopeId);

    if (subscope) {
      return subscope.rateValue;
    }

    return 0;
  }
}
